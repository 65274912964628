import { CustomerConfigService } from '@medlogic/shared/gecore';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { ActivatedRoute, Params } from '@angular/router';
import { OnDestroy, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { toArray } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
  EnStockChangeMode,
  ICadParam, ISmartSearch
} from '@medlogic/shared/shared-interfaces';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { LogService, LocalLibService } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
/** ATENÇÃO: Essa classe precisa estar aqui, no projeto principal, e não na library, pois,
 * realiza acesso a session o que é proibido para uma biblioteca.
 */
import { GlobalService } from '@medlogic/shared/shared-interfaces';

// import * as localStorage from 'localStorage';
import { of } from 'rxjs';
import { ITenantState, ITenant } from '@medlogic/shared/shared-interfaces';
import { cadParams } from './tenant';

@Injectable()
/* Define os métodos para resgate de parâmetros de configuração dos cadastros. */
export class MedlogicTenantService extends UnsubscribeOnDestroyAdapter implements OnDestroy {

  cadParams: ICadParam[];
  lstConfig: any[] = [];
  isLoading = true;
  urlGetDataSmartSearch = 'Data/GetDataSmartSearch?token=#TOKEN#&FraseBusca=';
  // https://sistema.medlogic.com.br/WebApi/api

  constructor(
    protected log: LogService,
    protected glb: GlobalService,
    protected cnf: ConfigStateService,
    protected cnfJson: ConfigJsonService,
    protected route: ActivatedRoute,
    protected modelComponent: ModelComponent,
    protected customerCnf: CustomerConfigService,
    protected http: HttpClient,
    protected store: Store<ITenantState>,
    protected lib: LocalLibService
  ) {
    super();
    // this.setCadParams();
  }

  get(tenant: ITenantState, isDebug: boolean = false): Observable<ITenant> {
    try {
      if (isDebug) {
        return this.getMockup();
      }

      // return this.setSessionAndGetCadParams(tenant?.tenantId)
      //   .pipe(
      //     map((params: ICadParam[]) => {
      //       return params.reduce((tnt, param) => {
      //         const key = param.idName;
      //         tnt[key] = key.endsWith('Ano') ? this.getParam(key, params) : this.getDefaultValue(key, params);
      //         return tnt;
      //       },
      //         { userName: tenant.loggedUserName } as ITenant
      //       );
      //     })
      //   );
      return this.setSessionAndGetCadParams(tenant?.tenantId)
        .pipe(
          map((params: ICadParam[]) => {
            return params.reduce((obj, item) => {
              const newObj = {};
              newObj[item.idName] = this.getParam(item.label, params);
              return ({ ...obj, ...newObj } as ITenant);
            }, {});
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'get', error.message);
    }
    return of(null);
  }

  protected getMockup(): Observable<ITenant> {
    try {
      return of({
        userName: 'username',
        genericAno: null,
        personAno: null,
        exameAno: null,
        exameResultadoAno: null,
      } as ITenant);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMockup', error.message);
    }
  }

  /* Valida se todas as chaves dos cadastros necessários já estão armazenados na session. */
  protected isAlreadSavedInLocalStorage(): boolean {
    try {
      if (!localStorage.getItem('modeloConfigNo')) { return false; }
      return cadParams.reduce((a, b) => a && !!localStorage.getItem(b.idName), false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isAlreadSavedInLocalStorage', error.message);
    }
    return false;
  }

  /* Deve ser definido em cada classe herdeira para especificar os parâmetros que devem ser resgatados do Cadastro de Configurações.
  * Preencher this.cadParams = [{ idName: 'CadNoNome', label: 'Rótulo' }, ...]
  */
  // protected setCadParams(): void {
  //   try {
  //     this.cadParams = [
  //       { idName: 'personAno', label: 'Cad_Paciente' },
  //       { idName: 'personPno', label: 'PNO_Paciente' },
  //       { idName: 'exameAno', label: 'CAD_EXAME' },
  //       { idName: 'examePno', label: 'PNO_EXAME' },
  //       { idName: 'frailtyAno', label: 'Cad_indice_fragilidade_atencao_pri' },
  //       { idName: 'frailtyPno', label: 'PNO_indice_fragilidade_atencao_pri' },
  //       { idName: 'exameResultadoAno', label: 'CAD_EXAME_RESULTADO' },
  //       { idName: 'exameResultadoPno', label: 'PNO_EXAME_RESULTADO' },
  //     ];
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'setCadParam', error.message);
  //   }
  // }

  /* Valida se todas as chaves dos cadastros necessários já estão armazenados na session. */
  protected isAlreadSavedInSession(): boolean {
    try {
      if (!localStorage.getItem('modeloConfigNo')) { return false; }
      return this.cadParams.reduce((a, b) => !!localStorage.getItem(a.toString()) && !!localStorage.getItem(b.toString()), false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isAlreadSavedInSession', error.message);
    }
    return false;
  }

  /* Retorna o id do CadParam */
  protected getParam(idName: string, params: ICadParam[] = null): number | string {
    try {
      params = params || cadParams;
      const finded = params.find(f => this.glb.isEqual(f.label, idName));
      return (finded && finded?.id > 0) ?
        (finded?.defaultValue ? finded?.defaultValue : finded?.id) :
        finded?.defaultValue || -1;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getParam', error.message);
    }
  }

  /* Retorna o valor default, conforme cadastrado no CAD - CONFIG IDOSO BEM CUIDADO (Registro e não apenas modelo). */
  protected getDefaultValue(idName: string, params: ICadParam[] = null): any {
    try {
      params = params || cadParams;
      const find = params ? params.find(f => this.glb.isEqual(f.idName, idName)) : null;
      return find ? find.defaultValue : null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getParam', error.message);
    }
  }

  /* Extrai os parâmetros passados na url/rota */
  protected getCadParams(route: ActivatedRoute): Observable<ICadParam[]> {
    try { // Os parametros estão sendo passados diretamente aos componentes
      return new Observable(observer => {
        try {
          if (this.isAlreadSavedInSession()) { // Se essa session já foi armazenada, todos os demais parâmetros também
            this.cnf.modeloConfigNo = parseInt(localStorage.getItem('modeloConfigNo'), 10);
            const params = this.fillParamsFromLocalStorage();
            // this.fillConfigParams(params);
            observer.next(params);
            observer.complete();
          }
          // Para aguardar o carregamento do config
          this.subs.sink = route.params
            .subscribe((params: Params) => {
              try {
                if (params.modeloConfigNo) {
                  this.cnf.modeloConfigNo = +params.modeloConfigNo;
                  localStorage.setItem('modeloConfigNo', this.cnf.modeloConfigNo.toString());
                }
                if (localStorage.getItem('modeloConfigNo') != null) {
                  this.cnf.modeloConfigNo = parseInt(localStorage.getItem('modeloConfigNo'), 10);
                  this.subs.sink = this.setSessionAndGetCadParams(this.cnf.modeloConfigNo)
                    .subscribe(p => {
                      try {
                        this.fillConfigParams(p);
                        observer.next(p);
                        observer.complete();
                      } catch (error) {
                        this.log.Registrar(this.constructor.name, 'getUrlParms.setSessionAngGetCadParams.subscribe', error.message);
                      }
                    });
                } else {
                  observer.next(null);
                  observer.complete();
                }
              } catch (error) {
                this.log.Registrar(this.constructor.name, 'getUrlParams.subscribe', error.message);
              }
            });
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'getUrlParams.Observer', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /* O config que é injetado em várias classes possui parâmetros de configuração que precisam ser preenchidos.
  */
  protected fillConfigParams(params: ICadParam[]): void {
    try {
      params.forEach(e => {
        try {
          this.cnf[e.idName] = e.id;
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'fillConfigParams.forEach', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillConfigParams', error.message);
    }
  }

  /* Resgata o Cadastro de Configurações que contém os ids dos demais cadastros, do serviço
  * As localStorage, uma para cada cadastro, será armazenada nesse método.
  */
  protected setSessionAndGetCadParams(modeloConfigNo: number): Observable<ICadParam[]> {
    try {
      if (modeloConfigNo > 0) {
        // Buscar dados de configuração
        return forkJoin([
          this.modelComponent
            .getDados(modeloConfigNo).pipe(
              toArray()),
          this.customerCnf
            .getAll(modeloConfigNo).pipe(
              toArray())
        ]).pipe(
          map(([lstCadastroConfig, customerCnfs]) => {
            cadParams
              .forEach(e => {
                try {
                  const itemCadastro = lstCadastroConfig.find(x => this.glb.isEqual(x.Rotulo, e.label));
                  if (itemCadastro) {
                    const cnf = customerCnfs[0];
                    e.id = parseInt(itemCadastro?.AtividadeCadastroNo, 10);
                    if (cnf && e.hasOwnProperty('property') && e.property) {
                      e.defaultValue = cnf[e.property];
                    } else if (itemCadastro.DefaultValue) {
                      e.defaultValue = itemCadastro.DefaultValue;
                    }
                    localStorage.setItem(e.idName, e?.id?.toString());
                  }
                } catch (error) {
                  this.log.Registrar(this.constructor.name, `getConfiguracao.forEach: ${e.label}`, error.message);
                }
              });
            return [...cadParams, this.fillExtraTenantProperties(lstCadastroConfig)];
          }),
          // this.fillExtraTenantProperties
        );
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getConfiguracao', error.message);
    }
    return of(null);
  }

  private fillExtraTenantProperties = (lstCadastroConfig: any): ICadParam => {
    try {
      let enStockChangeMode = EnStockChangeMode.checkin;
      // FIXME: o valor default NÃO está chegando - isso afeta a exibição do botão rejeitar
      const mode = this.lib.getDefault(lstCadastroConfig, 'Separacao_medicacao');
      if (mode) {
        enStockChangeMode = mode.toUpperCase();
      }
      return ({
        idName: 'enStockChangeMode',
        label: enStockChangeMode,
        defaultValue: enStockChangeMode
      } as ICadParam);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillExtraTenantProperties', error.message);
    }
    return null;
  }

  protected fillParamsFromLocalStorage(): ICadParam[] {
    try {
      cadParams?.forEach(e => {
        try {
          e.id = parseInt(localStorage.getItem(e.idName), 10);
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'fillParamsFromSession.forEach', error.message);
        }
      });
      return cadParams;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillParamsFromSession', error.message);
    }
  }

  getDataSmartSearch(pno: number): Observable<ISmartSearch> {
    try {
      const url = (`${this.cnfJson.baseUrlAPI}${this.urlGetDataSmartSearch}`).replace('#TOKEN#', this.cnf.baseUsuarioToken);
      return this.http.get<ISmartSearch>(url);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDataSmartSearch', error.message);
    }
    return of(null);
  }


}
